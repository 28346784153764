import React from "react";
import { 
  Box, 
  Typography, 
  TextField,
  Grid,
  Divider,
  FormHelperText
} from "@mui/material";
import { Person, Email, Phone } from '@mui/icons-material';

export default function CustomerForm({ formData, updateFormData, errors, styles }) {
  // Handler für Formularänderungen
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  return (
    <Box mt={5}>
      <Typography variant="h6" gutterBottom sx={styles?.sectionTitle}>
        Kundendaten
      </Typography>
      <Divider sx={styles?.divider} />
      
      {/* Getrennte Felder für Vor- und Nachname */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Vorname"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            InputProps={{
              startAdornment: <Person sx={{ mr: 1, color: '#7D1518' }} />,
            }}
            sx={styles?.textField}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Nachname"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            sx={styles?.textField}
          />
        </Grid>
      </Grid>
      
      <TextField
        required
        fullWidth
        margin="normal"
        label="E-Mail"
        name="customerEmail"
        type="email"
        value={formData.customerEmail}
        onChange={handleChange}
        error={!!errors.customerEmail}
        helperText={errors.customerEmail}
        InputProps={{
          startAdornment: <Email sx={{ mr: 1, color: '#7D1518' }} />,
        }}
        sx={styles?.textField}
      />
      
      <TextField
        required
        fullWidth
        margin="normal"
        label="Telefonnummer"
        name="customerPhone"
        value={formData.customerPhone}
        onChange={handleChange}
        error={!!errors.customerPhone}
        helperText={errors.customerPhone}
        InputProps={{
          startAdornment: <Phone sx={{ mr: 1, color: '#7D1518' }} />,
        }}
        sx={styles?.textField}
      />
      
      <FormHelperText>
        Alle Felder sind erforderlich für die Bearbeitung Ihrer Bestellung.
      </FormHelperText>
    </Box>
  );
}