import React, { useState } from 'react';
import { 
  TextField, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Box, 
  Typography, 
  Divider,
  Grid,
  Button,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper
} from '@mui/material';
import { Upload, Delete, Photo } from '@mui/icons-material';

export default function CustomFieldsForm({ formData, updateFormData, errors, styles }) {
  // State für hochgeladene Bilder
  const [hochgeladeneBilder, setHochgeladeneBilder] = useState([]);

  // Handler für Formularänderungen
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  // Spezifischer Handler für Bildupload
  const handleBilderUpload = (event) => {
    const dateien = Array.from(event.target.files);
    
    // Begrenzung auf max. 5 Bilder
    if (hochgeladeneBilder.length + dateien.length > 5) {
      alert('Sie können maximal 5 Bilder hochladen.');
      return;
    }
    
    // Dateigröße auf max. 5MB pro Bild begrenzen
    const zuGrosseDateien = dateien.filter(datei => datei.size > 5 * 1024 * 1024);
    if (zuGrosseDateien.length > 0) {
      alert('Einige Dateien sind größer als 5MB und können nicht hochgeladen werden.');
      return;
    }
    
    // Dateien in den State hinzufügen
    const neueBilder = dateien.map(datei => ({
      datei,
      vorschau: URL.createObjectURL(datei),
      name: datei.name
    }));
    
    setHochgeladeneBilder(prev => [...prev, ...neueBilder]);
    
    // Aktualisieren des Hauptformularzustands mit den Bildern
    updateFormData({
      kundeBilder: [...(formData.kundeBilder || []), ...dateien]
    });
    
    // Datei-Input zurücksetzen
    event.target.value = '';
  };

  // Handler zum Entfernen eines Bildes
  const handleBildEntfernen = (index) => {
    // Vorschau URL freigeben, um Speicherlecks zu vermeiden
    URL.revokeObjectURL(hochgeladeneBilder[index].vorschau);
    
    // Bild aus dem State entfernen
    const neueBilder = [...hochgeladeneBilder];
    neueBilder.splice(index, 1);
    setHochgeladeneBilder(neueBilder);
    
    // Aktualisieren des Hauptformularzustands
    const neueKundeBilder = [...(formData.kundeBilder || [])];
    neueKundeBilder.splice(index, 1);
    updateFormData({ kundeBilder: neueKundeBilder });
  };

  return (
    <>
      {/* Überzug */}
      <Box mt={5}>
        <Typography 
          variant="h6" 
          gutterBottom
          sx={styles?.sectionTitle}
        >
          Überzug
        </Typography>
        <Divider sx={styles?.divider} />
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl 
              fullWidth 
              margin="normal"
              error={!!errors.ueberzug}
              sx={styles?.formControl}
            >
              <InputLabel>Überzug</InputLabel>
              <Select
                name="ueberzug"
                value={formData.ueberzug || ''}
                onChange={handleChange}
                label="Überzug"
              >
                <MenuItem value="Fondant">Fondant</MenuItem>
                <MenuItem value="Buttercreme">Buttercreme</MenuItem>
                <MenuItem value="Türkische Sahne">Türkische Sahne</MenuItem>
                <MenuItem value="Marzipan">Marzipan</MenuItem>
              </Select>
              {errors.ueberzug && <FormHelperText>{errors.ueberzug}</FormHelperText>}
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              label="Farbe des Überzugs"
              name="ueberzugFarbe"
              value={formData.ueberzugFarbe || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.ueberzugFarbe}
              helperText={errors.ueberzugFarbe}
              sx={styles?.textField}
            />
          </Grid>
        </Grid>
        
        {formData.ueberzug && (
          <TextField
            label="Sonderwünsche zum Überzug (optional)"
            name="ueberzugHinweis"
            value={formData.ueberzugHinweis || ''}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            sx={styles?.textField}
          />
        )}
      </Box>

      {/* Modellierung */}
      <Box mt={5}>
        <Typography 
          variant="h6" 
          gutterBottom
          sx={styles?.sectionTitle}
        >
          Modellierung
        </Typography>
        <Divider sx={styles?.divider} />
        
        <FormControl 
          fullWidth 
          margin="normal"
          error={!!errors.modellierung}
          sx={styles?.formControl}
        >
          <InputLabel>Modellierung</InputLabel>
          <Select
            name="modellierung"
            value={formData.modellierung || ''}
            onChange={handleChange}
            label="Modellierung"
          >
            <MenuItem value="wie Bild">wie Bild</MenuItem>
            <MenuItem value="3D Modelliert">Modelliert 3D</MenuItem>
            <MenuItem value="ausgestochen">ausgestochen</MenuItem>
            <MenuItem value="Esspapier">Esspapier</MenuItem>
            <MenuItem value="Freitext">Freitext</MenuItem>
          </Select>
          {errors.modellierung && <FormHelperText>{errors.modellierung}</FormHelperText>}
        </FormControl>

        {formData.modellierung === 'wie Bild' && (
          <Box mt={2}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              startIcon={<Upload />}
              sx={styles?.submitButton}
            >
              Bilder hochladen
              <input
                type="file"
                hidden
                multiple
                accept="image/*"
                onChange={handleBilderUpload}
              />
            </Button>
            <FormHelperText>Sie können maximal 5 Bilder mit jeweils max. 5MB hochladen</FormHelperText>
            
            {/* Anzeige der hochgeladenen Bilder */}
            {hochgeladeneBilder.length > 0 && (
              <Paper sx={{ mt: 2, p: 2, maxHeight: '300px', overflow: 'auto' }}>
                <List dense>
                  {hochgeladeneBilder.map((bild, index) => (
                    <ListItem key={index}>
                      <Photo sx={{ mr: 2, color: '#7D1518' }} />
                      <ListItemText 
                        primary={bild.name} 
                        secondary={`${(bild.datei.size / (1024 * 1024)).toFixed(2)} MB`} 
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          onClick={() => handleBildEntfernen(index)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
        )}

        {formData.modellierung === 'Freitext' && (
          <TextField
            label="Beschreibung der gewünschten Modellierung"
            name="modellierungHinweis"
            value={formData.modellierungHinweis || ''}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            error={!!errors.modellierungHinweis}
            helperText={errors.modellierungHinweis}
            sx={styles?.textField}
          />
        )}
      </Box>

      {/* Dekoration */}
      <Box mt={5}>
        <Typography 
          variant="h6" 
          gutterBottom
          sx={styles?.sectionTitle}
        >
          Dekoration
        </Typography>
        <Divider sx={styles?.divider} />
        
        <FormControl 
          fullWidth 
          margin="normal"
          error={!!errors.dekoration}
          sx={styles?.formControl}
        >
          <InputLabel>Dekoration</InputLabel>
          <Select
            name="dekoration"
            value={formData.dekoration || ''}
            onChange={handleChange}
            label="Dekoration"
          >
            <MenuItem value="wie Bild" disabled={formData.modellierung !== 'wie Bild'}>
              wie Bild
            </MenuItem>
            <MenuItem value="Drip">Drip</MenuItem>
            <MenuItem value="Rosen">Rosen</MenuItem>
            <MenuItem value="sonstige Deko">sonstige Deko</MenuItem>
            <MenuItem value="Freitext">Freitext</MenuItem>
          </Select>
          {errors.dekoration && <FormHelperText>{errors.dekoration}</FormHelperText>}
        </FormControl>

        {formData.dekoration === 'Drip' && (
          <TextField
            label="Farbe"
            name="dekorationFarbe"
            value={formData.dekorationFarbe || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.dekorationFarbe}
            helperText={errors.dekorationFarbe}
            sx={styles?.textField}
          />
        )}

        {formData.dekoration === 'sonstige Deko' && (
          <TextField
            label="Farbe (optional)"
            name="dekorationFarbe"
            value={formData.dekorationFarbe || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            sx={styles?.textField}
          />
        )}

        {formData.dekoration === 'Rosen' && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl 
                fullWidth 
                margin="normal"
                error={!!errors.rosenArt}
                sx={styles?.formControl}
              >
                <InputLabel>Art der Rosen</InputLabel>
                <Select
                  name="rosenArt"
                  value={formData.rosenArt || ''}
                  onChange={handleChange}
                  label="Art der Rosen"
                >
                  <MenuItem value="frisch">frisch</MenuItem>
                  <MenuItem value="künstlich">künstlich</MenuItem>
                  <MenuItem value="marzipan/zucker">Marzipan/Zucker</MenuItem>
                </Select>
                {errors.rosenArt && <FormHelperText>{errors.rosenArt}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Farbe der Rosen"
                name="dekorationFarbe"
                value={formData.dekorationFarbe || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.dekorationFarbe}
                helperText={errors.dekorationFarbe}
                sx={styles?.textField}
              />
            </Grid>
          </Grid>
        )}

        {formData.dekoration === 'Freitext' && (
          <TextField
            label="Beschreibung der gewünschten Dekoration"
            name="dekorationHinweis"
            value={formData.dekorationHinweis || ''}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            error={!!errors.dekorationHinweis}
            helperText={errors.dekorationHinweis}
            sx={styles?.textField}
          />
        )}
      </Box>

      {/* Schrift */}
      <Box mt={5}>
        <Typography 
          variant="h6" 
          gutterBottom
          sx={styles?.sectionTitle}
        >
          Schrift
        </Typography>
        <Divider sx={styles?.divider} />
        
        <FormControl 
          fullWidth 
          margin="normal"
          error={!!errors.schrift}
          sx={styles?.formControl}
        >
          <InputLabel>Schrift</InputLabel>
          <Select
            name="schrift"
            value={formData.schrift || ''}
            onChange={handleChange}
            label="Schrift"
          >
            <MenuItem value="Topper">Topper</MenuItem>
            <MenuItem value="ausgestochen">ausgestochen</MenuItem>
            <MenuItem value="Handschrift">Handschrift</MenuItem>
            <MenuItem value="Freitext">Freitext</MenuItem>
          </Select>
          {errors.schrift && <FormHelperText>{errors.schrift}</FormHelperText>}
        </FormControl>

        {(formData.schrift === 'ausgestochen' || formData.schrift === 'Handschrift' || formData.schrift === 'Topper') && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl 
                fullWidth 
                margin="normal"
                error={!!errors.schriftPosition}
                sx={styles?.formControl}
              >
                <InputLabel>Position</InputLabel>
                <Select
                  name="schriftPosition"
                  value={formData.schriftPosition || ''}
                  onChange={handleChange}
                  label="Position"
                >
                  <MenuItem value="wie im Bild">wie im Bild</MenuItem>
                  <MenuItem value="auf der Torte">auf der Torte</MenuItem>
                  <MenuItem value="am Rand">am Rand</MenuItem>
                  <MenuItem value="auf dem Teller">auf dem Teller</MenuItem>
                  <MenuItem value="auf einem Schild">auf einem Schild</MenuItem>
                </Select>
                {errors.schriftPosition && <FormHelperText>{errors.schriftPosition}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Farbe"
                name="schriftFarbe"
                value={formData.schriftFarbe || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                sx={styles?.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Gewünschter Text"
                name="schriftText"
                value={formData.schriftText || ''}
                onChange={handleChange}
                fullWidth
                multiline
                rows={2}
                margin="normal"
                sx={styles?.textField}
              />
            </Grid>
          </Grid>
        )}

        {formData.schrift === 'Freitext' && (
          <TextField
            label="Wunschtext zur Schriftgestaltung"
            name="schriftHinweis"
            value={formData.schriftHinweis || ''}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            error={!!errors.schriftHinweis}
            helperText={errors.schriftHinweis}
            sx={styles?.textField}
          />
        )}
      </Box>
    </>
  );
}