import { createTheme } from "@mui/material";

// Angepasstes Theme mit den Shopfarben
const theme = createTheme({
  palette: {
    primary: {
      main: '#7D1518', // Akzentfarbe
      light: '#9A3336',
      dark: '#5A0F11',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1A1B18', // Textfarbe
    },
    background: {
      default: '#f3edcf', 
      paper: '#f3edcf',   
    },
    text: {
      primary: '#1A1B18',
      secondary: '#1A1B18',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#7D1518',
          height: '2px',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#7D1518', 
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#7D1518',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#7D1518',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#7D1518',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#7D1518',
          '&:hover': {
            backgroundColor: '#5E1013',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#7D1518',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#7D1518',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#E8F5E9',
          color: '#1B5E20',
        },
        standardError: {
          backgroundColor: '#FFEBEE',
          color: '#B71C1C',
        },
      },
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      color: '#7D1518',
    },
  },
});

export default theme;