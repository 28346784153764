import React from "react";
import OrderFormContainer from "./components/forms/OrderFormContainer";

const CreateDraftOrder = () => {
  return (
    <>
      <OrderFormContainer />
    </>
  );
};

export default CreateDraftOrder;