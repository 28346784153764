import React, { useState } from "react";
import axios from "axios";
import { 
  Box, 
  Button, 
  Alert, 
  ThemeProvider, 
  Paper, 
  Container,
  Typography,
  Snackbar
} from "@mui/material";

import theme from "./theme";
import { formStyles as styles } from "./styles";
import ProductInfoForm from "./ProductInfoForm";
import PickupForm from "./PickupForm";
import CakeDetailsForm from "./CakeDetailsForm";
import CustomFieldsForm from "./CustomFieldsForm";
import CustomerForm from "./CustomerForm";

// Basis-URL für API-Anfragen
const BASE_URL = process.env.REACT_APP_API_URL || "";

// Initiale Formulardaten
const initialFormData = {
  // Produkt
  productName: "",
  productPrice: "",
  
  // Abholung
  location: "",
  pickupDate: "",
  pickupTime: "",
  
  // Tortendetails
  shape: "",
  tier: 1,
  size: "",
  height: "",
  customSize: "",
  fillings: [],
  modelling: "",
  decoration: "",
  
  // Überzug
  ueberzug: "",
  ueberzugFarbe: "",
  ueberzugHinweis: "",
  
  // Modellierung
  modellierung: "",
  modellierungHinweis: "",
  kundeBilder: [],
  
  // Dekoration
  dekoration: "",
  dekorationFarbe: "",
  dekorationHinweis: "",
  rosenArt: "",
  
  // Schrift
  schrift: "",
  schriftPosition: "",
  schriftHinweis: "",
  
  // Kundendaten
  firstName: "",
  lastName: "",
  customerEmail: "",
  customerPhone: ""
};

export default function OrderFormContainer() {
  // Zentraler Zustand für alle Formulardaten
  const [formData, setFormData] = useState(initialFormData);
  
  // UI-Zustände
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  // Validierungsfunktion
  const validateForm = () => {
    const newErrors = {};
    
    // Produktvalidierung
    if (!formData.productName) newErrors.productName = "Bitte geben Sie einen Tortennamen ein";
    if (!formData.productPrice) newErrors.productPrice = "Bitte geben Sie einen Preis ein";
    
    // Abholungvalidierung
    if (!formData.location) newErrors.location = "Bitte wählen Sie einen Standort";
    if (!formData.pickupDate) newErrors.pickupDate = "Bitte wählen Sie ein Abholdatum";
    if (!formData.pickupTime) newErrors.pickupTime = "Bitte wählen Sie eine Abholzeit";
    
    // Tortendetailsvalidierung
    if (!formData.shape) newErrors.shape = "Bitte wählen Sie eine Form";
    
    // Größenvalidierung hängt von der Form ab
    if (formData.shape === "Freiform") {
      if (!formData.customSize) newErrors.customSize = "Bitte geben Sie die gewünschten Maße ein";
    } else {
      if (!formData.size) newErrors.size = "Bitte wählen Sie eine Größe";
      
      // Höhe ist nur bei bestimmten runden Torten erforderlich
      if (formData.shape === "Rund" && formData.tier === 1 && 
          (formData.size === "20 cm" || formData.size === "26 cm") && 
          !formData.height) {
        newErrors.height = "Bitte wählen Sie eine Höhe";
      }
      
      // Für mehrstöckige runde Torten ist die Höhe immer erforderlich
      if (formData.shape === "Rund" && formData.tier > 1 && !formData.height) {
        newErrors.height = "Bitte wählen Sie eine Höhe";
      }
    }
    
    // Füllungen
    if (formData.fillings.length === 0) newErrors.fillings = "Bitte wählen Sie mindestens eine Füllung";
    
    // Überzugvalidierung
    if (!formData.ueberzug) newErrors.ueberzug = "Bitte wählen Sie einen Überzug aus";
    if (formData.ueberzug && !formData.ueberzugFarbe) newErrors.ueberzugFarbe = "Bitte geben Sie die Farbe des Überzugs an";
    
    // Modellierungsvalidierung
    if (!formData.modellierung) newErrors.modellierung = "Bitte wählen Sie eine Modellierung";
    if (formData.modellierung === 'Freitext' && !formData.modellierungHinweis) {
      newErrors.modellierungHinweis = "Bitte beschreiben Sie die gewünschte Modellierung";
    }
    if (formData.modellierung === 'Bild des Kunden' && (!formData.kundeBilder || formData.kundeBilder.length === 0)) {
      newErrors.kundeBilder = "Bitte laden Sie mindestens ein Bild hoch";
    }
    
    // Dekorationsvalidierung (optional, aber wenn ausgewählt mit Details)
    if (formData.dekoration === 'Drip' && !formData.dekorationFarbe) {
      newErrors.dekorationFarbe = "Bitte geben Sie eine Farbe an";
    }
    if (formData.dekoration === 'Piping' && !formData.dekorationFarbe) {
      newErrors.dekorationFarbe = "Bitte geben Sie eine Farbe an";
    }
    if (formData.dekoration === 'Rosen' && !formData.rosenArt) {
      newErrors.rosenArt = "Bitte wählen Sie eine Art der Rosen";
    }
    if (formData.dekoration === 'Freitext' && !formData.dekorationHinweis) {
      newErrors.dekorationHinweis = "Bitte beschreiben Sie die gewünschte Dekoration";
    }
    
    // Schriftvalidierung (optional, aber wenn ausgewählt mit Details)
    if (formData.schrift && ['Cut-Out', 'Handschrift'].includes(formData.schrift) && !formData.schriftPosition) {
      newErrors.schriftPosition = "Bitte wählen Sie eine Position";
    }
    if (formData.schrift === 'Freitext' && !formData.schriftHinweis) {
      newErrors.schriftHinweis = "Bitte geben Sie Ihre Wünsche zur Schrift an";
    }
    
    // Kundendaten - angepasst für Vor-/Nachname
    if (!formData.firstName) newErrors.firstName = "Bitte geben Sie Ihren Vornamen ein";
    if (!formData.lastName) newErrors.lastName = "Bitte geben Sie Ihren Nachnamen ein";
    if (!formData.customerEmail) newErrors.customerEmail = "Bitte geben Sie Ihre E-Mail ein";
    if (!formData.customerPhone) newErrors.customerPhone = "Bitte geben Sie Ihre Telefonnummer ein";
    
    // Validiere E-Mail-Format
    if (formData.customerEmail && !/\S+@\S+\.\S+/.test(formData.customerEmail)) {
      newErrors.customerEmail = "Bitte geben Sie eine gültige E-Mail-Adresse ein";
    }
    
    // Validiere Telefonnummer
    if (formData.customerPhone && !/^[0-9+\-\s]{8,}$/.test(formData.customerPhone)) {
      newErrors.customerPhone = "Bitte geben Sie eine gültige Telefonnummer ein";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Helper zur Erstellung der vollständigen Größen-/Höhenangabe
  const getFullSizeDescription = () => {
    if (formData.shape === "Freiform" && formData.customSize) {
      return formData.customSize;
    }
    
    if (!formData.size) return "";
    
    // Wenn die Größe bereits die vollständige Formatierung enthält
    if (formData.size.includes("×")) {
      return formData.size;
    }
    
    // Wenn keine Höhe gewählt wurde oder nötig ist
    if (!formData.height) {
      // Bei runden Torten mit mehreren Etagen ist Höhe immer erforderlich
      if (formData.shape === "Rund" && formData.tier > 1) {
        return formData.size;
      }
      // Bei runden Torten mit einer Etage und Durchmesser 18 cm ist Höhe auf 10 cm festgelegt
      if (formData.shape === "Rund" && formData.tier === 1 && formData.size === "18 cm × 10 cm") {
        return formData.size;
      }
      return formData.size;
    }
    
    // Für runde Torten mit separater Höhenauswahl
    return `${formData.size} × ${formData.height}`;
  };
  
  // Formular absenden
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      // Erstelle aussagekräftige Fehlermeldung
      const errorCategories = [];
      if (errors.productName || errors.productPrice) errorCategories.push("Produktinformationen");
      if (errors.location || errors.pickupDate || errors.pickupTime) errorCategories.push("Abholinformationen");
      if (errors.shape || errors.size || errors.height || errors.customSize || errors.fillings) {
        errorCategories.push("Tortendetails");
      }
      if (errors.ueberzug || errors.ueberzugFarbe) errorCategories.push("Überzug");
      if (errors.modellierung || errors.modellierungHinweis || errors.kundeBilder) {
        errorCategories.push("Modellierung");
      }
      if (errors.dekoration || errors.dekorationFarbe || errors.dekorationHinweis || errors.rosenArt) {
        errorCategories.push("Dekoration");
      }
      if (errors.schrift || errors.schriftPosition || errors.schriftHinweis) {
        errorCategories.push("Schrift");
      }
      if (errors.firstName || errors.lastName || errors.customerEmail || errors.customerPhone) {
        errorCategories.push("Kundendaten");
      }
      
      const errorMessage = errorCategories.length > 0
        ? `Bitte überprüfen Sie folgende Bereiche: ${errorCategories.join(", ")}`
        : "Bitte füllen Sie alle erforderlichen Felder aus.";
        
      setErrorMessage(errorMessage);
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Prüfen ob Bilder hochgeladen wurden
      const hasBilder = formData.kundeBilder && formData.kundeBilder.length > 0;
      let apiData;
      
      if (hasBilder) {
        // FormData für Bildupload erstellen
        apiData = new FormData();
        
        // Bereite die vollständige Größenangabe vor
        const fullSizeDescription = getFullSizeDescription();
        
        // JSON-Daten zum FormData hinzufügen
        const jsonData = {
          // Produkt-Basisdaten
          productName: String(formData.productName || ""),
          productPrice: String(formData.productPrice || ""),
          
          // Füllungen als einfaches Array von Strings
          fillings: Array.isArray(formData.fillings) 
            ? formData.fillings.map(String) 
            : [],
          
          // Eigenschaften der Torte in sauberer Form
          properties: {
            location: String(formData.location || ""),
            date: String(formData.pickupDate || ""),
            time: String(formData.pickupTime || ""),
            Shape: String(formData.shape || ""),
            Tier: Number(formData.tier || 1),
            Diameter: String(fullSizeDescription || ""),
            Height: String(formData.height || ""),
            modelling: String(formData.modelling || ""),
            decoration: String(formData.decoration || ""),
            
            // Neue properties
            ueberzug: String(formData.ueberzug || ""),
            ueberzugFarbe: String(formData.ueberzugFarbe || ""),
            ueberzugHinweis: String(formData.ueberzugHinweis || ""),
            modellierung: String(formData.modellierung || ""),
            modellierungHinweis: String(formData.modellierungHinweis || ""),
            dekoration: String(formData.dekoration || ""),
            dekorationFarbe: String(formData.dekorationFarbe || ""),
            dekorationHinweis: String(formData.dekorationHinweis || ""),
            rosenArt: String(formData.rosenArt || ""),
            schrift: String(formData.schrift || ""),
            schriftPosition: String(formData.schriftPosition || ""),
            schriftHinweis: String(formData.schriftHinweis || "")
          },
          
          // Kundendaten
          firstName: String(formData.firstName || ""),
          lastName: String(formData.lastName || ""),
          customerEmail: String(formData.customerEmail || ""),
          customerPhone: String(formData.customerPhone || "")
        };
        
        apiData.append('data', JSON.stringify(jsonData));
        
        // Alle Bilder hinzufügen
        formData.kundeBilder.forEach((bild, index) => {
          apiData.append(`bild_${index}`, bild);
        });
        
        // API-Anfrage mit FormData
        const response = await axios.post(`${BASE_URL}/api/user/register`, apiData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        
        // Weiterleitung zum Bestell-Link in Shopify
        const checkoutUrl = response.data.checkoutUrl;
        window.open(checkoutUrl, '_blank');
        setFormData(initialFormData);
        setErrors({});
        setSuccessMessage("Bestellung erfolgreich übermittelt!");
      } else {
        // Bereite die vollständige Größenangabe vor
        const fullSizeDescription = getFullSizeDescription();
        
        // Erstelle ein sauberes, bereinigtes Objekt für die API-Anfrage
        const cleanPayload = {
          // Produkt-Basisdaten
          productName: String(formData.productName || ""),
          productPrice: String(formData.productPrice || ""),
          
          // Füllungen als einfaches Array von Strings
          fillings: Array.isArray(formData.fillings) 
            ? formData.fillings.map(String) 
            : [],
          
          // Eigenschaften der Torte in sauberer Form
          properties: {
            location: String(formData.location || ""),
            date: String(formData.pickupDate || ""),
            time: String(formData.pickupTime || ""),
            Shape: String(formData.shape || ""),
            Tier: Number(formData.tier || 1),
            Diameter: String(fullSizeDescription || ""),
            Height: String(formData.height || ""),
            modelling: String(formData.modelling || ""),
            decoration: String(formData.decoration || ""),
            
            // Neue properties
            ueberzug: String(formData.ueberzug || ""),
            ueberzugFarbe: String(formData.ueberzugFarbe || ""),
            ueberzugHinweis: String(formData.ueberzugHinweis || ""),
            modellierung: String(formData.modellierung || ""),
            modellierungHinweis: String(formData.modellierungHinweis || ""),
            dekoration: String(formData.dekoration || ""),
            dekorationFarbe: String(formData.dekorationFarbe || ""),
            dekorationHinweis: String(formData.dekorationHinweis || ""),
            rosenArt: String(formData.rosenArt || ""),
            schrift: String(formData.schrift || ""),
            schriftPosition: String(formData.schriftPosition || ""),
            schriftHinweis: String(formData.schriftHinweis || "")
          },
          
          // Kundendaten
          firstName: String(formData.firstName || ""),
          lastName: String(formData.lastName || ""),
          customerEmail: String(formData.customerEmail || ""),
          customerPhone: String(formData.customerPhone || "")
        };
        
        // Senden der Anfrage
        const response = await axios.post(`${BASE_URL}/api/user/register`, cleanPayload);
        
        // Weiterleitung zum Bestell-Link in Shopify
        const checkoutUrl = response.data.checkoutUrl;
        window.open(checkoutUrl, '_blank');
        setFormData(initialFormData);
        setErrors({});
        setSuccessMessage("Bestellung erfolgreich übermittelt!");
      }
    } catch (error) {
      console.error("Fehler beim Absenden:", error);
      setErrorMessage("Fehler beim Absenden der Bestellung.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Hilfsfunktion für Formularänderungen
  const updateFormData = (newData) => {
    setFormData(prev => ({
      ...prev,
      ...newData
    }));
  };
  
  // Handler für Schließen der Snackbar
  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={styles.container}>
        {/* Logo und Header */}
        <Box sx={styles.headerContainer}>
          <Box 
            component="img" 
            src="https://cdn.shopify.com/s/files/1/0286/9149/7057/files/Hasan_Oezdag_Logo_neu.jpg?v=1613537861"
            alt="Hasan Oezdag Logo"
            sx={styles.logo}
          />
          <Typography
            variant="h4"
            align="center"
            sx={styles.headerTitle}
          >
            Individuelle Tortenbestellung
          </Typography>
        </Box>
        
        <Paper 
          component="form" 
          onSubmit={handleSubmit}
          elevation={3}
          sx={styles.paper}
        >
          {/* Produktinformationen */}
          <ProductInfoForm 
            formData={formData} 
            updateFormData={updateFormData} 
            errors={errors} 
            styles={styles}
          />
          
          {/* Abholung / Lieferung */}
          <PickupForm 
            formData={formData} 
            updateFormData={updateFormData} 
            errors={errors} 
            styles={styles}
          />
          
          {/* Tortendetails */}
          <CakeDetailsForm 
            formData={formData} 
            updateFormData={updateFormData} 
            errors={errors} 
            styles={styles}
          />
          
          {/* Erweiterte Felder */}
          <CustomFieldsForm 
            formData={formData} 
            updateFormData={updateFormData} 
            errors={errors} 
            styles={styles}
          />
          
          {/* Kundendaten */}
          <CustomerForm 
            formData={formData} 
            updateFormData={updateFormData} 
            errors={errors} 
            styles={styles}
          />
          
          {/* Absenden */}
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={styles.submitButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Wird gesendet..." : "Bestellung absenden"}
          </Button>
        </Paper>
        
        {/* Success/Error Snackbars */}
        <Snackbar 
          open={!!successMessage} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        
        <Snackbar 
          open={!!errorMessage} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}