import React from "react";
import { 
  Box, 
  TextField, 
  Typography, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Grid, 
  Divider,
  FormHelperText 
} from "@mui/material";
import { CalendarMonth, AccessTime, Place } from '@mui/icons-material';

export default function PickupForm({ formData, updateFormData, errors, styles }) {
  // Handler für alle Formularänderungen
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };
  
  // Zeiten für Abholung (9:00 - 18:00 Uhr)
  const getAvailableTimes = () => {
    const times = [];
    for (let hour = 9; hour <= 18; hour++) {
      for (let minute of [0, 30]) {
        if (hour === 18 && minute === 30) continue;
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  };
  
  // Mindestdatum ist heute + 1 Tag
  const getMinDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today.toISOString().split("T")[0];
  };
  
  // Prüft, ob das Datum ein Wochenende ist
  const isWeekend = (dateString) => {
    if (!dateString) return false;
    const date = new Date(dateString);
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sonntag, 6 = Samstag
  };

  return (
    <Box mt={5}>
      <Typography variant="h6" gutterBottom sx={styles?.sectionTitle}>
        Abholung / Lieferung
      </Typography>
      <Divider sx={styles?.divider} />
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl 
            fullWidth 
            margin="normal"
            error={!!errors.location}
            required
            sx={styles?.formControl}
          >
            <InputLabel>Standort</InputLabel>
            <Select
              name="location"
              value={formData.location}
              onChange={handleChange}
              startAdornment={<Place sx={{ ml: 1, mr: 1, color: '#7D1518' }} />}
              label="Standort"
            >
              <MenuItem value="Keupstrasse">Keupstrasse</MenuItem>
              <MenuItem value="Stammheim">Stammheim</MenuItem>
              <MenuItem value="Lieferung">Lieferung (Aufpreis)</MenuItem>
            </Select>
            {errors.location && <FormHelperText>{errors.location}</FormHelperText>}
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <TextField
            required
            label="Abholdatum"
            name="pickupDate"
            type="date"
            fullWidth
            margin="normal"
            value={formData.pickupDate}
            onChange={handleChange}
            error={!!errors.pickupDate}
            helperText={errors.pickupDate || (
              isWeekend(formData.pickupDate) ? 
                "Hinweis: Sie haben einen Samstag oder Sonntag gewählt" : ""
            )}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: getMinDate() }}
            InputProps={{
              startAdornment: <CalendarMonth sx={{ mr: 1, color: '#7D1518' }} />,
            }}
            sx={styles?.textField}
          />
        </Grid>
        
        <Grid item xs={12} md={4}>
          <FormControl 
            fullWidth 
            margin="normal"
            error={!!errors.pickupTime}
            required
            sx={styles?.formControl}
          >
            <InputLabel>Abholzeit</InputLabel>
            <Select
              name="pickupTime"
              value={formData.pickupTime}
              onChange={handleChange}
              startAdornment={<AccessTime sx={{ ml: 1, mr: 1, color: '#7D1518' }} />}
              label="Abholzeit"
            >
              {getAvailableTimes().map(time => (
                <MenuItem key={time} value={time}>
                  {time} Uhr
                </MenuItem>
              ))}
            </Select>
            {errors.pickupTime && <FormHelperText>{errors.pickupTime}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}