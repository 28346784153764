import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Chip,
  Divider,
  FormHelperText,
} from "@mui/material";

// Konstanten für Formularoptionen - "Quadratisch" zu "Eckig" geändert
const shapes = ["Rund", "Eckig", "Herz", "Freiform"];
const tiers = [1, 2, 3, 4, 5, 6, 7];

// Konfigurationen für Größen abhängig von Form und Etagen
const sizeConfigurations = {
  Rund: {
    // Einstöckige Torten
    1: [
      { value: "12 cm", label: "12 cm (Höhe: 10 cm)" },
      { value: "18 cm", label: "18 cm" },
      { value: "20 cm", label: "20 cm" },
      { value: "26 cm", label: "26 cm" }
    ],
    // Mehrstöckige Torten - für jede Anzahl von Etagen gibt es Kombinationen
    2: [
      { value: "22 cm + 15 cm", label: "22 cm + 15 cm" },
      { value: "26 cm + 17 cm", label: "26 cm + 17 cm" },
      { value: "30 cm + 20 cm", label: "30 cm + 20 cm" }
    ],
    3: [
      { value: "30 cm + 20 cm + 12 cm", label: "30 cm + 20 cm + 12 cm" },
      { value: "35 cm + 25 cm + 15 cm", label: "35 cm + 25 cm + 15 cm" },
      { value: "40 cm + 30 cm + 20 cm", label: "40 cm + 30 cm + 20 cm" }
    ],
    4: [
      { value: "40 cm + 30 cm + 20 cm + 12 cm", label: "40 cm + 30 cm + 20 cm + 12 cm" },
      { value: "45 cm + 35 cm + 25 cm + 15 cm", label: "45 cm + 35 cm + 25 cm + 15 cm" }
    ],
    5: [
      { value: "50 cm + 40 cm + 30 cm + 20 cm + 12 cm", label: "50 cm + 40 cm + 30 cm + 20 cm + 12 cm" }
    ],
    6: [
      { value: "60 cm + 50 cm + 40 cm + 30 cm + 20 cm + 12 cm", label: "60 cm + 50 cm + 40 cm + 30 cm + 20 cm + 12 cm" }
    ],
    7: [
      { value: "70 cm + 60 cm + 50 cm + 40 cm + 30 cm + 20 cm + 12 cm", label: "70 cm + 60 cm + 50 cm + 40 cm + 30 cm + 20 cm + 12 cm" }
    ]
  },
  // "Quadratisch" zu "Eckig" umbenannt und neue Größe hinzugefügt
  Eckig: [
    { value: "20 × 20 × 8 cm", label: "20 × 20 × 8 cm" },
    { value: "20 × 20 × 10 cm", label: "20 × 20 × 10 cm" },
    { value: "20 × 30 × 8 cm", label: "20 × 30 × 8 cm" }, // Neue Größe
    { value: "24 × 34 × 8 cm", label: "24 × 34 × 8 cm" },
    { value: "30 × 30 × 8 cm", label: "30 × 30 × 8 cm" },
    { value: "30 × 40 × 8 cm", label: "30 × 40 × 8 cm" },
    { value: "30 × 50 × 8 cm", label: "30 × 50 × 8 cm" },
    { value: "40 × 40 × 8 cm", label: "40 × 40 × 8 cm" },
    { value: "40 × 60 × 8 cm", label: "40 × 60 × 8 cm" },
    { value: "50 × 50 × 8 cm", label: "50 × 50 × 8 cm" },
    { value: "60 × 80 × 8 cm", label: "60 × 80 × 8 cm" }
  ],
  // Neue Variante für 18 cm bei Herzform hinzugefügt
  Herz: [
    { value: "18 × 8 cm", label: "18 × 8 cm" }, // Neue Größenoptionen
    { value: "18 × 10 cm", label: "18 × 10 cm" },
    { value: "18 × 12 cm", label: "18 × 12 cm" },
    { value: "20 × 8 cm", label: "20 × 8 cm" },
    { value: "20 × 10 cm", label: "20 × 10 cm" },
    { value: "20 × 12 cm", label: "20 × 12 cm" },
    { value: "26 × 8 cm", label: "26 × 8 cm" },
    { value: "30 × 8 cm", label: "30 × 8 cm" },
    { value: "35 × 8 cm", label: "35 × 8 cm" },
    { value: "40 × 8 cm", label: "40 × 8 cm" },
    { value: "45 × 8 cm", label: "45 × 8 cm" },
    { value: "50 × 8 cm", label: "50 × 8 cm" },
    { value: "60 × 8 cm", label: "60 × 8 cm" }
  ]
};

// Höhenoptionen für runde Torten - Angepasst gemäß Kundenwunsch
const heightOptions = {
  // 12 cm Durchmesser hat fixe Höhe von 10 cm
  singleTier: {
    "18 cm": ["10 cm", "12 cm", "14 cm"],  // Neue Höhenoptionen für 18 cm
    standard: ["8 cm", "10 cm", "12 cm", "15 cm", "20 cm"]
  },
  multiTier: ["10 cm", "12 cm", "15 cm"]
};

const fillingsList = [
  "Schokolade", "Vanille", "Haselnuss", "Walnuss", "Nougat",
  "Kaffee", "Erdbeere", "Himbeere", "Kirsch", "Obstfüllung",
  "Bananencreme", "Bananenstücke", "Pfirsich Maracuja", "Zitrone", 
  "Mango", "Beerenmix", "Mascarpone", "Kinderbueno", "Rafaello",
];

export default function CakeDetailsForm({ formData, updateFormData, errors, styles }) {
  // Verfügbare Größen basierend auf Form und Etagen
  const [availableSizes, setAvailableSizes] = useState([]);
  
  // Verfügbare Höhen basierend auf Tier und Size
  const [availableHeights, setAvailableHeights] = useState([]);
  
  // Handler für alle Formularänderungen
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "shape") {
      // Bei Änderung der Form zurücksetzen der abhängigen Felder
      updateFormData({
        shape: value,
        tier: value === "Rund" ? formData.tier : 1,
        size: "",
        height: "",
        customSize: ""
      });
    } else if (name === "tier" && formData.shape === "Rund") {
      // Bei Änderung der Etagenanzahl für runde Torten
      updateFormData({
        tier: parseInt(value),
        size: "",
        height: ""
      });
    } else {
      // Standardfall für alle anderen Felder
      updateFormData({ [name]: value });
    }
  };
  
  // Spezielle Handler für Checkboxen (Füllungen)
  const handleFillingChange = (filling) => {
    const current = [...formData.fillings];
    if (current.includes(filling)) {
      updateFormData({
        fillings: current.filter(f => f !== filling)
      });
    } else if (current.length < 2) {
      updateFormData({
        fillings: [...current, filling]
      });
    }
  };
  
  // Bei Änderung von Form oder Etagen aktualisieren wir die verfügbaren Größen
  useEffect(() => {
    if (formData.shape === "Rund") {
      // Bei runden Torten hängt die Größe von der Anzahl der Etagen ab
      const tierOptions = sizeConfigurations.Rund[formData.tier] || [];
      setAvailableSizes(tierOptions);
      
      // Bei einer Etage und bestimmten Größen Höhenauswahl anzeigen
      if (formData.tier === 1) {
        // 12 cm hat fixe Höhe von 10 cm, keine Auswahl nötig
        if (formData.size === "12 cm") {
          setAvailableHeights([]);
        } else if (formData.size === "18 cm") {
          // Für 18 cm spezielle Höhenoptionen
          setAvailableHeights(heightOptions.singleTier["18 cm"]);
        } else if (formData.size === "20 cm" || formData.size === "26 cm") {
          // Für andere Größen die Standard-Höhenoptionen
          setAvailableHeights(heightOptions.singleTier.standard);
        } else {
          setAvailableHeights([]);
        }
      } else {
        // Mehrere Etagen haben gemeinsame Höhenoptionen
        setAvailableHeights(heightOptions.multiTier);
      }
    } else if (formData.shape === "Eckig") { // "Quadratisch" zu "Eckig" geändert
      // Bei eckigen Torten sind die Größen fix
      setAvailableSizes(sizeConfigurations.Eckig);
      setAvailableHeights([]);
    } else if (formData.shape === "Herz") {
      // Bei herzförmigen Torten sind die Größen fix
      setAvailableSizes(sizeConfigurations.Herz);
      setAvailableHeights([]);
    } else {
      // Bei Freiform keine vordefinierten Größen
      setAvailableSizes([]);
      setAvailableHeights([]);
    }
  }, [formData.shape, formData.tier]);
  
  // Bei Änderung der Größe für runde Torten mit einer Etage
  useEffect(() => {
    if (formData.shape === "Rund" && formData.tier === 1) {
      if (formData.size === "12 cm") {
        // Für 12 cm ist die Höhe fix 10 cm
        setAvailableHeights([]);
        // Implizit Höhe auf 10 cm setzen
        updateFormData({ height: "10 cm" });
      } else if (formData.size === "18 cm") {
        // Spezifische Höhen für 18cm
        setAvailableHeights(heightOptions.singleTier["18 cm"]);
      } else if (formData.size === "20 cm" || formData.size === "26 cm") {
        // Für 20 cm und 26 cm kann die Höhe aus Standard-Optionen gewählt werden
        setAvailableHeights(heightOptions.singleTier.standard);
      } else {
        setAvailableHeights([]);
      }
    }
  }, [formData.size, formData.shape, formData.tier]);
  
  // Rendert die Größenauswahl basierend auf der Form
  const renderSizeSelection = () => {
    if (!formData.shape) return null;
    
    if (formData.shape === "Freiform") {
      return (
        <TextField
          required
          fullWidth
          margin="normal"
          label="Maße (Länge × Breite × Höhe oder Beschreibung)"
          name="customSize"
          value={formData.customSize}
          onChange={handleChange}
          error={!!errors.customSize}
          helperText={errors.customSize}
          placeholder="z.B. 30 × 20 × 10 cm oder individuelle Beschreibung"
          sx={styles?.textField}
        />
      );
    }
    
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={formData.shape === "Rund" && (availableHeights.length > 0 || formData.size === "12 cm") ? 6 : 12}>
          <FormControl 
            fullWidth 
            margin="normal"
            error={!!errors.size}
            required
          >
            <InputLabel>Größe</InputLabel>
            <Select
              name="size"
              value={formData.size}
              onChange={handleChange}
              label="Größe"
            >
              {availableSizes.map((size, index) => (
                <MenuItem key={index} value={size.value}>
                  {size.label}
                </MenuItem>
              ))}
            </Select>
            {errors.size && <FormHelperText>{errors.size}</FormHelperText>}
          </FormControl>
        </Grid>
        
        {/* Höhenauswahl für runde Torten, wenn nötig */}
        {formData.shape === "Rund" && availableHeights.length > 0 && (
          <Grid item xs={12} md={6}>
            <FormControl 
              fullWidth 
              margin="normal"
              error={!!errors.height}
              required
            >
              <InputLabel>Höhe</InputLabel>
              <Select
                name="height"
                value={formData.height}
                onChange={handleChange}
                label="Höhe"
              >
                {availableHeights.map((height) => (
                  <MenuItem key={height} value={height}>
                    {height}
                  </MenuItem>
                ))}
              </Select>
              {errors.height && <FormHelperText>{errors.height}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box mt={5}>
      <Typography variant="h6" gutterBottom sx={styles?.sectionTitle}>
        Tortendetails
      </Typography>
      <Divider sx={styles?.divider} />
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={formData.shape === "Rund" ? 6 : 12}>
          <FormControl 
            fullWidth 
            margin="normal"
            error={!!errors.shape}
            required
            sx={styles?.formControl}
          >
            <InputLabel>Form</InputLabel>
            <Select 
              name="shape" 
              value={formData.shape} 
              onChange={handleChange}
              label="Form"
            >
              {shapes.map(shape => (
                <MenuItem key={shape} value={shape}>
                  {shape}
                </MenuItem>
              ))}
            </Select>
            {errors.shape && <FormHelperText>{errors.shape}</FormHelperText>}
          </FormControl>
        </Grid>
        
        {/* Etagenauswahl nur für runde Torten */}
        {formData.shape === "Rund" && (
          <Grid item xs={12} md={6}>
            <FormControl 
              fullWidth 
              margin="normal"
              required
              sx={styles?.formControl}
            >
              <InputLabel>Etagen</InputLabel>
              <Select 
                name="tier" 
                value={formData.tier} 
                onChange={handleChange}
                label="Etagen"
              >
                {tiers.map(tier => (
                  <MenuItem key={tier} value={tier}>
                    {tier}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      
      {/* Größenauswahl */}
      {renderSizeSelection()}
      
      {/* Füllungen */}
      <Box mt={3}>
        <FormControl 
          component="fieldset" 
          fullWidth 
          margin="normal"
          error={!!errors.fillings}
          required
          sx={styles?.formControl}
        >
          <FormLabel component="legend" sx={{ color: '#7D1518' }}>Füllungen (max. 2)</FormLabel>
          {errors.fillings && <FormHelperText>{errors.fillings}</FormHelperText>}
          
          <Box sx={styles?.fillingContainer || { display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            {formData.fillings.map(filling => (
              <Chip 
                key={filling}
                label={filling}
                onDelete={() => handleFillingChange(filling)}
                color="primary"
              />
            ))}
          </Box>
          
          <FormGroup sx={styles?.fillingGrid || { mt: 2, display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}>
            {fillingsList.map(filling => (
              <FormControlLabel
                key={filling}
                control={
                  <Checkbox
                    checked={formData.fillings.includes(filling)}
                    onChange={() => handleFillingChange(filling)}
                    disabled={formData.fillings.length >= 2 && !formData.fillings.includes(filling)}
                  />
                }
                label={filling}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
      
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Modellierung (optional)"
              name="modelling"
              value={formData.modelling}
              onChange={handleChange}
              margin="normal"
              placeholder="z.B. Figuren, Tiere, etc."
              multiline
              rows={2}
              sx={styles?.textField}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Dekoration (optional)"
              name="decoration"
              value={formData.decoration}
              onChange={handleChange}
              margin="normal"
              placeholder="z.B. Blumen, Schriftzug, etc."
              multiline
              rows={2}
              sx={styles?.textField}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}