import React from "react";
import { Box, TextField, Typography, Grid, Divider } from "@mui/material";

export default function ProductInfoForm({ formData, updateFormData, errors, styles }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={styles?.sectionTitle}>
        Produktinformationen
      </Typography>
      <Divider sx={styles?.divider} />
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            required
            fullWidth
            label="Tortenname"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            error={!!errors.productName}
            helperText={errors.productName}
            margin="normal"
            sx={styles?.textField}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            fullWidth
            label="Preis (€)"
            name="productPrice"
            type="number"
            inputProps={{ step: "0.01", min: "0" }}
            value={formData.productPrice}
            onChange={handleChange}
            error={!!errors.productPrice}
            helperText={errors.productPrice}
            margin="normal"
            sx={styles?.textField}
          />
        </Grid>
      </Grid>
    </Box>
  );
}