// CSS-Styles für das Bestellformular

export const formStyles = {
  container: {
    backgroundColor: '#f3edcf', 
    minHeight: '100vh',
    py: 4,
  },
  
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 4,
  },
  
  logo: {
    maxWidth: 300,
    width: '100%',
    height: 'auto',
    mb: 3,
  },
  
  headerTitle: {
    color: '#7D1518',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
  
  paper: {
    p: 4,
    borderRadius: 2,
    backgroundColor: '#FFFFFF', 
    border: '1px solid #7D1518',
  },
  
  sectionTitle: {
    color: '#7D1518',
    fontWeight: 'bold',
    mb: 1,
  },
  
  divider: {
    mb: 3,
  },
  
  icon: {
    mr: 1,
    color: '#7D1518',
  },
  
  formControl: {
    margin: '16px 0',
  },
  
  fillingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    mt: 1,
  },
  
  fillingGrid: {
    mt: 2,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
  
  submitButton: {
    mt: 4,
    p: 1.5,
    backgroundColor: '#7D1518',
    '&:hover': {
      backgroundColor: '#5E1013',
    },
  },
  
  feedbackAlert: {
    mt: 2,
  },
  
  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#7D1518',
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: '#7D1518',
      },
    },
  },
};